<template>
	<!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon-left">이전 페이지</i>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					나의 리뷰
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container">
		<div id="sub" class="sub mypage">
			<!-- content -->
			<section class="content">
				<div class="box">
					<!-- [D] 리뷰들 -->
					<div class="myreview">
						<div class="myreview__wrap">
							<h3 class="review__title">{{t('10327')}}</h3>
							<div class="flex">
								<div class="review__subtitle">{{hotel_nm}}</div>
								<div class="review__grad">
									<i v-for="i in 5" :key="i" class="icon" :class="i <= reviewScore ? 'icon-star' : 'icon-star--light'" @click="setReviewScore(i)"></i>
								</div>
							</div>
							<div class="flex">
								<div class="review__type">
									{{prod_nm}} - {{prod_type}}
								</div>
							</div>
							<!-- 리뷰 입력 -->
							<div class="review__write">
								<!-- [D] 등록된 리뷰 있을 때 border-color 변경 -->
								<div class="review__text" style="border-color:#000">
									<textarea class="ta ta--transparent" v-model="reviewText" maxlength="200" :placeholder="t('10834')" >
									</textarea>
									<!-- [D] 텍스트 숫자 변경 -->
									<div class="review__number">
										<span>{{reviewText.length}}</span> / 200
									</div>
								</div>
								<div class="review__attachs">
									<div class="carousel">
										<!-- [REST] 등록된 리뷰 이미지 호출  -->
										<div class="carousel__slider" data-slider="carousel">
											<swiper :slides-per-view="4.5" :space-between="8">
												<swiper-slide>
													<div class="review__attach">
														<div class="review__attachwrap">
															<label>
																<div class="review__attachbox">
																	<i class="icon icon-camera"></i>
																	<span>{{t('10376')}}({{images.length}}/5)</span>
																</div>
																<input type="file" multiple class="blind" @change="addImage">
																<div  v-for="file in file_name" :key="file">
																	<input type="hidden" :value="file.file_id">
																</div>
															</label>
														</div>
													</div>
												</swiper-slide>
												 <swiper-slide v-for="file in file_name" :key="file.file_id">
													<div class="review__attach">
														<div class="review__attachwrap">
															<div class="review__attachbox" :style="{'background-image': 'url(https://dg395odoxs1uw.cloudfront.net/viewfile/' + file.file_id + ')'}">
																<button type="button" class="review__close" multiple @click="deleteImage(image.SUB_SEQ)"> 
																	<i class="icon icon-closesmall"></i>
																</button>
															</div>
														</div>
													</div>
												</swiper-slide> 
											</swiper>
										</div>
									</div>

								</div>
							</div>
							<!-- 리뷰 선택 -->
							<div class="review__select">
								<div class="tag">
									<h3 class="tag__title">
										{{t('10374')}}
									</h3>
									<!--[D] 체크된 항목 checked 속성 추가 -->
									<ul class="tag__list">
										<li v-for="reviewCode in reviewCodes1" :key="reviewCode.CODE">
											<label>
												<input type="radio" class="chk blind" :value="reviewCode.CODE" v-model="reviewCd1">
												<span class="tag__text">
													<i class="icon" :class="reviewCode.CODE === '001' ? 'icon-good' : 'icon-bad'"></i>{{reviewCode.CODE_NM}}
												</span>
											</label>
										</li>
									</ul>
									<ul class="tag__list" v-if="reviewCd1 === '002'">
										<li v-for="reviewCode in reviewCodes2" :key="reviewCode.CODE">
											<label>
												<input type="checkbox" class="chk blind" :value="reviewCode.CODE" v-model="reviewCd2">
												<span class="tag__text">
													{{reviewCode.CODE_NM}}
												</span>
											</label>
										</li>
									</ul>
								</div>
								<div class="review__selectnotice">
									{{t('10375')}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<div id="navigation" class="navigation navigation--noborder">
		<div class="btns btns--centered">
			<button type="button" class="btn" :class="reviewText.length >= 5 ? 'btn-confirm' : 'btn-disabled'" :disabled="reviewText.length < 5" @click="handleSubmit">등록하기</button>
		</div>
	</div>
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>

</template>

<script>
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex';
import ErrorAlert from "@/components/layers/Message_alert";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

export default {
	components: {
		ErrorAlert,
	},
	setup() {
		const router = useRouter();
		const { t }= useI18n() //번역필수 모듈
		const route = useRoute();
		const {
			hotel_no, prod_no, booking_no,
			hotel_nm, prod_nm, prod_type,
		} = route.query;
		const reviewText = ref("");
		const openErrorAlert = ref(false)
		const reviewScore = ref(5);
		const reviewCd1 = ref(null);
		const reviewCd2 = ref([]);
		const store = useStore();
		const reviewCodes = computed(() => store.state.mmy01.reviewCodes);
		const images = ref([]);
		const file_name = ref([]);
		const userData = computed(() => store.state.userData);
		
		onMounted(() => {
			store.dispatch("mmy01/fetchReviewCodes");
		})

		const deleteImage = async (subSeq) => {
			images.value = images.value.filter(image => image.SUB_SEQ !== subSeq);
		}
		
		const addImage = async (e) => {
			images.value = e.target.files
			if(images.value.length > 5){
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "리뷰이미지는 5개까지만 업로드 가능합니다."});
				openErrorAlert.value = true;
				//alert("리뷰이미지는 5개까지만 업로드 가능합니다.")
				images.value = []
				return;
			}
			for(var i = 0; i < images.value.length; i++){
				let send_data = new FormData();
                send_data.append('file', images.value[i]);
                send_data.append('metadata', 'upload');
                fetch('https://dg395odoxs1uw.cloudfront.net/uploadimage', {
                  method: 'POST',
                  headers: {
                      'accept': 'application/json',
                  },
                  body: send_data
                }).then((response) => response.json())
                .then((response) => {
                  var file_id = response.data[0].file_id; //json 변수로 받는 문법
				  //console.log(file_id)
				  file_name.value.push({file_id})
				})
			}
			
      		setTimeout(function () {
					//console.log(file_name.value[0].file_id)
      		}, 100);
		}
		
		const handleSubmit = async () => {
			const data = await store.dispatch("mmy01/uploadReview", 
			{
				hotel_no,
				prod_no,
				booking_no,
				mem_id: userData.value.mem_token,
				score: reviewScore.value, 
				Review: reviewText.value, 
				review_cd: reviewCd1.value, 
				review_cd2: reviewCd2.value.join(","),
			});
			var file_names = ''
			for(var i = 0; i < images.value.length; i++){
				if (file_names === ''){
					file_names = file_name.value[i].file_id
				}else{
					file_names = file_names +','+ file_name.value[i].file_id
				}
			}
			await store.dispatch("mmy01/upsertImage", 
				{
					mem_id: userData.value.mem_token,
					seq: data.Res_data[0].SEQ,
					hotel_no: hotel_no,
					images: file_names
				});
			if(data.Res_cd === "0000") {
				router.back();
			}else {
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "업로드에 실패하였습니다."});
				openErrorAlert.value = true;
				//alert("업로드에 실패하였습니다.");
			}
		}

		return {
			goBack: () => {
				router.back();
			},
			reviewText,
			reviewScore,
			setReviewScore: (score) => {
				reviewScore.value = score;
			},
			reviewCd1,
			reviewCd2,
			reviewCodes1: computed(() => reviewCodes.value.filter(reviewCode => reviewCode.GRP_VALUE === "1").sort((a, b) => Number(a.CODE) - Number(b.CODE))),
			reviewCodes2: computed(() => reviewCodes.value.filter(reviewCode => reviewCode.GRP_VALUE.length === 0).sort((a, b) => Number(a.CODE) - Number(b.CODE))),
			handleSubmit,
			images,
			addImage,
			file_name,
			deleteImage,
			hotel_nm,
			prod_nm,
			openErrorAlert,
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
			prod_type,
			t,
			i18n
		}
	},
}
</script>